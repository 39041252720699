const styles = {
  /* base */
  baseStyle: {
    margin: 0,
    padding: 0,
    fontFamily: "-apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
    "-webkit-font-smoothing": "antialiased",
    "-moz-osx-font-smoothing": "grayscale",
    fontSize: '1.4rem'
  },

  fullWidthHeight: {
    minHeight: '100%',
    width: '100%'
  },

  spacing_tightest: {
    padding: '0.4rem 0.6rem'
  },

  spacing_tight: {
    padding: '1.4rem 1.6rem'
  },

  spacing: {
    padding: '2rem'
  },

  spacing_page: {
    height: '100%',
    width: '100%',
    padding: '2rem 1.6rem',

    '@media (min-width: 520px)': {
      padding: '2rem 7rem'
    },

    '@media (min-width: 768px)': {
      padding: '2rem 14rem'
    }
  },

  /**
   * Attempt at basic grid
   */
  columns: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'stretch'
  },

  columns_startAligned: {
    justifyContent: 'flex-start'
  },

  columns__one_of_one: {
    width: '100%'
  },

  columns__one_of_two: {
    width: '100%',

    '@media (min-width: 768px)': {
      width: '49%'
    }
  },

  columns__one_of_three: {
    width: '100%',
    padding: '0.4rem 0',

    '@media (min-width: 768px)': {
      width: '32%',
      padding: 'inherit'
    }
  },


  columns__one_of_four: {
    width: '100%',
    padding: '0.4rem 0',

    '@media (min-width: 520px)': {
      width: '25%',
      padding: 'inherit'
    }
  },

  /**
   * Common styles
   */
  note: {
    color: '#647482'
  },

  text_center: {
    textAlign:'center'
  }
}

export default styles
