import { NOTIFICATIONS } from './../../_constants/notifications'

export const ADD_NOTIFICATION   = 'ADD_NOTIFICATION'
export const DELETE_NOTIFICATION  = 'DELETE_NOTIFICATION'

/**
 * Adds a notification to the list.
 * Each notification component (Toast, Banner, Alert) dismissed (deletes) notifications after they are done with it
 * @param {*} id
 * @param {*} content
 * @param {*} type
 */
export const addNotification = (id, content, type) => ({
  type: ADD_NOTIFICATION,
  payload: { id, content, type }
})

/**
 * Look up a notification in `constants` folder by id and add it to the list
 * @param {*} id
 */
export const addNotificationById = (id) => {
  return (dispatch) => {
    let foundNotification

    for (let notification in NOTIFICATIONS) {
      if (NOTIFICATIONS[notification].id === id) {
        foundNotification = NOTIFICATIONS[notification]
        continue
      }
    }

    if (foundNotification)
      dispatch(addNotification(foundNotification.id,
                               foundNotification.text,
                               foundNotification.type))
  }
}

/**
 * Dismisses/deletes a notification from the list.
 * @param {*} id
 */
export const deleteNotification = (id) => ({
  type: DELETE_NOTIFICATION,
  payload: { id }
})