import React from 'react'
import withStyles from 'react-jss'
import globalStyles from '../../utilities/styles'
import { _frankieAppHost } from './../../_constants/globals'
import { EmptyState, Page } from '@shopify/polaris'

/**
 *
 * @param {*} props
 */
const WelcomePage = (props) => {
  const { classes, onComplete } = props

  return (
    <div className={ classes.spacing_page }>
      <Page
        title="Welcome"
      >
        <EmptyState
          heading="Record your store visitors"
          image={`${_frankieAppHost}/assets/post.svg`}
          action={{
            content: 'Get started',
            onAction: () => onComplete(false)
          }}
          secondaryAction={{
            content: 'Learn more',
            onAction: () => onComplete(true)
          }}
        >
        <p>Comply and assist with future contact tracing needs.</p>
        </EmptyState>
      </Page>
    </div>
  )
}

export default withStyles({...globalStyles})(WelcomePage)