import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { deleteNotification } from '../../_redux/actions/notifications'
import { Toast } from '@shopify/app-bridge-react'

/**
 * Opens a modal with viewmode explanation about the first time charge.
 */
class ToastGenerator extends React.Component {

  /**
   * Remove from notification list
   */
  onClose = (id) => {
    this.props.dispatch(deleteNotification(id))
  }

  render() {
    const { notifications } = this.props

    if (!notifications || !notifications.length)
      return (null)

    // if localhost
    if (window.location.hostname === 'localhost') {
      window.alert(notifications[0].text)
      return (null)
    }

    return (
      <Fragment>
        { notifications.map((notification, i) => {
            return (
              <Toast content={notification.text}
                     error={notification.type === 'error'}
                     onDismiss={() => this.onClose(notification.id)}
                     duration={2000}
                     key={notification.id + '-' + i}
              />
            )
          })
        }
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications
  }
}

export default connect(mapStateToProps)(ToastGenerator)