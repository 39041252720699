import React from 'react'
import ReactDOM from 'react-dom'
import { AppProvider } from '@shopify/polaris'
import { Provider } from '@shopify/app-bridge-react'
import translations from '@shopify/polaris/locales/en.json'

import './index.css'
import '@shopify/polaris/styles.css'
import App from './App/App'
import { getShopDomain } from './services/window'
import { _shopifyApiKey } from './_constants/globals'


// For Fullstory running in iframe
window['_fs_is_outer_script'] = true


ReactDOM.render(
  <Provider config={{ apiKey:  _shopifyApiKey, shopOrigin: getShopDomain(), forceRedirect: false }} >
    <AppProvider i18n={ translations }>
      <App />
    </AppProvider>
  </Provider>,
  document.getElementById('root'))