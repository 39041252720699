import React, { Component } from 'react'
import { connect } from 'react-redux'
import withStyles from 'react-jss'
import globalStyles from '../../utilities/styles'
import { getShopDomain } from '../../services/window'
import { scrollToTheTopPlease } from '../../utilities/windowHandler'
import { Layout, Button, Page, Card, Stack, TextContainer } from '@shopify/polaris'
import VisitorQR from './VisitorQR'
import './QRCode.css'
var QRCode = require('qrcode.react')


class CTQRCode extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showVisitor: false,
      storeDomain: this.props.storeDomain
    }
  }

  /**
   * React lifecycle call, first one to get called after component mounted (after render)
   */
  componentDidMount () {
    // something
  }

  /**
   * React lifecycle call. If props change this method will be triggered.
   * Async logic for when shopId is obtained from API, as initially it will be undefined
   */
  componentDidUpdate (prevProps) {
    // something
  }

  render () {
    const { showVisitor, storeDomain } = this.state
    return (
      <Page
          title="Your QR Code"
        >
          { 
            showVisitor ? <VisitorQR storeDomain={storeDomain}/> :
            <Layout>
            <Layout.Section >
              <Card title="Instructions" sectioned>
                <TextContainer spacing="loose">
                  <p>Print this QR Code and display it in a location where your visitors can scan the code with their phone camera.
                     They will be prompted to register their details in a contactless, self-serve way. 
                     You can also display the QR code using a device if you do not wish to print it.</p>
                  <p>The QR code is personalized to your business. Visitors who scan this QR code, 
                    will have their personal details associated with your business, time and date stamped at the time they filled in the form.</p>
                  <p>If you have multiple locations, enter the location prior to printing (or displaying) the QR code.</p><br/>
                </TextContainer>
                <Stack distribution="trailing">
                  <Button primary
                    onClick= { this.handlePageView }
                    >Print / Display</Button>
                </Stack>
              </Card>
            </Layout.Section>
            <Layout.Section secondary>
                <Card title="" sectioned>
                  <div className='qrCode' style={{ textAlign: 'center'}}>
                    <QRCode id='qr' value={`"https://${storeDomain}/safe?s=${getShopDomain()}"`}  renderAs='svg' size={1024}/>
                  </div>
                  <a target="_blank" rel="noopener noreferrer" href={`https://${storeDomain}/safe?s=${getShopDomain()}`} 
                    style={{ textAlign: 'center'}}>
                    Link to this form</a>
                </Card>
              </Layout.Section>
            </Layout>
          }
        </Page>
    )
  }

  handlePageView = async () => {
    this.setState({ showVisitor: true })
    scrollToTheTopPlease()
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    shopId: state.shop.id
  }
}

export default connect(mapStateToProps)(withStyles(globalStyles)(CTQRCode))