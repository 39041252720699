import React from 'react'
import withStyles from 'react-jss'
import globalStyles from '../../utilities/styles'
import { _frankieAppHost, _frankieAppHomepage } from './../../_constants/globals'
import { EmptyState } from '@shopify/polaris'

/**
 *
 * @param {*} props
 */
const FatalErrorPage = (props) => {
  const { classes } = props

  return (
    <div className={ classes.spacing_page }>
      <EmptyState
        heading="Oops, something went wrong"
        image={`${_frankieAppHost}/assets/404.svg`}
        action={{
          content: 'Reload app',
          onAction: () => {
            window.top.location.href = _frankieAppHomepage
          }
        }}
      >
        <p>There was an unexpected technical error while loading the app.</p><br />

        <p>If you're looking to go to the app, please try reloading or contact us if that still does not work.</p><br />
        <p>If you were installing the app, please uninstall from the apps page and then re-install.</p>
      </EmptyState>
    </div>
  )
}

export default withStyles({...globalStyles})(FatalErrorPage)