import { addNotificationById } from './notifications'
import { NOTIFICATIONS } from './../../_constants/notifications'
import hipleeApiService from '../../services/hipleeApi'

export const FETCH_SHOPINFO       = 'FETCH_SHOPINFO'
export const SET_SHOP             = 'SET_SHOP'
export const SAVE_SHOP_INTEGRATION= 'SAVE_SHOP_INTEGRATION'
export const SAVE_SHOP_CONTACT= 'SAVE_SHOP_CONTACT'


export const setShop = (shop) => ({
  type: SET_SHOP,
  payload: { isLoading: false, ...shop }
})

const fetchShopInfoBegin = () => ({
  type: FETCH_SHOPINFO,
  payload: { isLoading: true, status: 'fetching' }
})

const fetchShopInfoSuccess = response => ({
  type: FETCH_SHOPINFO,
  payload: { isLoading: false, status: 'success', response }
})

const fetchShopInfoFailure = error => ({
  type: FETCH_SHOPINFO,
  payload: { isLoading: false, status: 'error', error }
})

export const fetchStoreInfo = () => {
  return (dispatch, getState) => {
    dispatch(fetchShopInfoBegin())
    hipleeApiService.getMerchant(getState().token)
      .then(response => response.json())
      .then(response => {
        if (getState().shop.processingStatus &&
            getState().shop.processingStatus !== response.processingStatus &&
            response.processingStatus === 'completed')
          dispatch(addNotificationById(NOTIFICATIONS.PROCESSING_COMPLETED.id))
        dispatch(fetchShopInfoSuccess(response))
      })
      .catch(err => {
        dispatch(fetchShopInfoFailure(err))
      })
  }
}

export const fetchStoreInfoWithRetries = () => {
  return (dispatch, getState) => {
    dispatch(fetchShopInfoBegin())
    hipleeApiService.getMerchant(getState().token)
      .then(response => response.json())
      .then(response => {
        if (getState().shop.processingStatus &&
            getState().shop.processingStatus !== response.processingStatus &&
            response.processingStatus === 'completed')
          dispatch(addNotificationById(NOTIFICATIONS.PROCESSING_COMPLETED.id))

        if (response.processingStatus !== 'completed') {
          setTimeout(() => dispatch(fetchStoreInfoWithRetries(), 30000)) // 30 seconds
        }
        dispatch(fetchShopInfoSuccess(response))
      })
      .catch(err => {
        dispatch(fetchShopInfoFailure(err))
      })
  }
}


const saveIntegrationBegin = () => ({
  type: SAVE_SHOP_INTEGRATION,
  payload: { isLoading: true }
})

const saveIntegrationSuccess = (response, key, value) => ({
  type: SAVE_SHOP_INTEGRATION,
  payload: { isLoading: false, status: 'success', response, integration: { [key]: value } }
})

const saveIntegrationFailure = error => ({
  type: SAVE_SHOP_INTEGRATION,
  payload: { isLoading: false, status: 'error', error }
})

const saveContactBegin = () => ({
  type: SAVE_SHOP_CONTACT,
  payload: { isLoading: true }
})

const saveContactSuccess = (response) => ({
  type: SAVE_SHOP_CONTACT,
  payload: { isLoading: false, status: 'success', response }
})

const saveContactFailure = error => ({
  type: SAVE_SHOP_CONTACT,
  payload: { isLoading: false, status: 'error', error }
})

export const saveContact = (payload) => {
  return (dispatch) => {
    dispatch(saveContactBegin())
    hipleeApiService.saveContact(payload)
      .then(async response => {
        if (response.status !== 200) {
          throw new Error('Unable to save the contact')
        } else {
          dispatch(saveContactSuccess(response))
        }
      })
      .catch(error => {
        dispatch(saveContactFailure(error))
      })
  }
}

export const saveIntegration = (key, value) => {
  return (dispatch, getState) => {
    dispatch(saveIntegrationBegin())
    hipleeApiService.putMerchant(getState().token, key, value)
      .then(async response => {
        if (response.status === 301) {
          const body = await response.json()
          window.top.location.href = body.redirectURL
        } else if (response.status !== 200) {
          throw new Error('Unable to update integration')
        } else {
          dispatch(saveIntegrationSuccess(response, key, value))
        }
      })
      .catch(error => {
        dispatch(saveIntegrationFailure(error))
      })
  }
}