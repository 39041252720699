import { FETCH_SHOPINFO, SET_SHOP, SAVE_SHOP_INTEGRATION, SAVE_SHOP_CONTACT } from '../actions/shop'

const initialState = {
  id: undefined,
  processingStatus: undefined,
  lastDataSync: undefined,
  currency: '$',
  email: undefined,
  revenue: undefined,
  pricingBracket: {},
  flags: {},
  integrations: {},
  contacts: {},
  showConfirmationForAppConfirmation: false,
  isLoading: true
}

export const shop = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SHOPINFO:
      return {
        ...state,
        ...action.payload.response,
        isLoading: action.payload.isLoading,
        error: action.payload.status === 'error' ? action.payload.error : null
      }
    case SET_SHOP:
      return {
        ...state,
        ...action.payload,
        isLoading: action.payload.isLoading,
        error: action.payload.status === 'error' ? action.payload.error : null
      }
    case SAVE_SHOP_INTEGRATION:
      return {
        ...state,
        ...action.payload.response,
        integrations: { ...state.integrations, ...action.payload.integration },
        isLoading: action.payload.isLoading,
        error: action.payload.status === 'error' ? action.payload.error : null
      }
    case SAVE_SHOP_CONTACT:
      return {
        ...state,
        ...action.payload.response,
        contacts: { ...state.contacts, ...action.payload.contacts },
        isLoading: action.payload.isLoading,
        error: action.payload.status === 'error' ? action.payload.error : null
      }
    default:
      return state
  }
}