import React, { Component } from 'react'
import { connect } from 'react-redux'
import withStyles from 'react-jss'
import globalStyles from '../../utilities/styles'
import { saveContact } from '../../_redux/actions/shop'
import { addNotificationById } from '../../_redux/actions/notifications'
import { NOTIFICATIONS } from '../../_constants/notifications'
import { getShopDomain } from '../../services/window'
import { Layout, Button, Page, Card, Form, FormLayout, TextField, Stack, InlineError, Checkbox } from '@shopify/polaris'

class AddContacts extends Component {
  constructor (props) {
    super(props)

    this.state = {
      contactName: '',
      contactPhone: '',
      contactEmail: '',
      storeLocaton: '',
      shop: getShopDomain()
    }
  }

  /**
   * React lifecycle call, first one to get called after component mounted (after render)
   */
  componentDidMount () {
    //something
  }

  /**
   * React lifecycle call. If props change this method will be triggered.
   * Async logic for when shopId is obtained from API, as initially it will be undefined
   */
  componentDidUpdate (prevProps) {
    //something
  }

  handleContactSave = () => {
    this.props.dispatch(saveContact( {
                                      shop:  this.state.shop,
                                      name: this.state.contactName,
                                      email: this.state.contactEmail,
                                      phone: this.state.contactPhone,
                                      location: this.state.storeLocation ? this.state.storeLocation : 'default'
                                    }))
    this.props.dispatch(addNotificationById(NOTIFICATIONS.CONTACT_SAVED.id))
    // unset for reentry
    this.setState({ contactName: '' })
    this.setState({ contactEmail: '' })
    this.setState({ contactPhone: '' })
    this.setState({ storeLocation: '' })
  }

  isInvalid = (value, pattern) => {
    if (!value || value.length > 120) {
      return true
    }
    return !new RegExp("^" + pattern + "$").test(value)
  }

  render () {
    const { contactName, contactPhone, contactEmail, storeLocation } = this.state
    const emailFieldPattern = "[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(.[a-z0-9-]+)*"
    const nameFieldPattern = "[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*"
    const phoneFieldPattern = "[0-9]*"
    const isInvalidEmail = this.isInvalid(contactEmail, emailFieldPattern) || contactEmail.length < 5
    const isInvalidPhone = this.isInvalid(contactPhone, phoneFieldPattern) || contactPhone.length > 14 || contactPhone.length < 5
    const isInvalidName = this.isInvalid(contactName, nameFieldPattern) || contactName.length < 5
    const errorEmail = isInvalidEmail &&  contactEmail.length > 5 ? 'Valid example@domain.something' : ''
    const errorPhone = isInvalidPhone &&  contactPhone.length > 5 ? 'Digits only' : ''
    const errorName = isInvalidName &&  contactName.length > 5 ? 'Joe Bloggs' : ''

    let isValid = (isInvalidEmail || isInvalidPhone || isInvalidName) ? false : true
    return (
      <Page
        title='Manually add visitor'
      >
        <Layout>
          <Layout.AnnotatedSection
            title=''
            description='Add visitor detals here. For a contactless, visitor self-serve option, use the ‘Generate QR’ button.'
          >
            <Card sectioned>
              <Form>
                <FormLayout>
                  <TextField
                    id='contactName'
                    value={contactName}
                    onChange={this.handleChange('contactName')}
                    placeholder='Please enter the full name'
                    autoComplete={true}
                    label='Full Name'
                    type='text'
                    pattern={nameFieldPattern}
                    minLength={5}
                  />
                  <InlineError message={errorName} fieldID={contactName} />
                  <TextField
                    id='contactPhone'
                    value={contactPhone}
                    onChange={this.handleChange('contactPhone')}
                    placeholder='Please enter a phone number'
                    autoComplete={true}
                    label='Phone Number'
                    type='tel'
                    pattern={phoneFieldPattern}
                    minLength={5}
                  />
                  <InlineError message={errorPhone} fieldID={contactPhone} />
                  <TextField
                    id='contactEmail'
                    value={contactEmail}
                    onChange={this.handleChange('contactEmail')}
                    placeholder='Please enter an email'
                    autoComplete={true}
                    label='Email Address'
                    type='email'
                    pattern={emailFieldPattern}
                    minLength={5}
                  />
                  <InlineError message={errorEmail} fieldID={contactEmail} />
                  <TextField
                    id='storeLocation'
                    value={storeLocation}
                    onChange={this.handleChange('storeLocation')}
                    placeholder='Enter a store location (if you have multiple)'
                    label='Store Location'
                    type='text'
                  />
                  <Checkbox
                    label="The visitor consents to share their information for contact tracing"
                    checked={true}
                  />
                  <Stack distribution='trailing'>
                    <Button primary submit
                      onClick= {() => this.handleContactSave()}
                      disabled= {!isValid}
                    >
                      Save
                    </Button>
                  </Stack>
                </FormLayout>
              </Form>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </Page>
    )
  }

  handleChange = (field) => {
    return (value) => { 
      this.setState({ [field]: value })
    }
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    shopId: state.shop.id
  }
}

export default connect(mapStateToProps)(withStyles(globalStyles)(AddContacts))