import { ADD_NOTIFICATION, DELETE_NOTIFICATION } from '../actions/notifications'

/**
 * Example of notification:
 * {
 *    id: 12,
 *    text: "alert",
 *    type: "error"
 * }
 *
 * @param {*} state
 * @param {*} action
 */
export const notifications = (state = [], action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      // TODO: don't duplicate notifications with same id
      return [
        ...state,
        {
          id: action.payload.id,
          text: action.payload.content,
          type: action.payload.type
        }
      ]
    case DELETE_NOTIFICATION:
      return state.filter((notification) => {
        return notification.id !== action.payload.id
      })
    default:
      return state
  }
}