import React, { Component } from 'react'
import withStyles from 'react-jss'
import globalStyles from '../../utilities/styles'
import { getShopDomain } from '../../services/window'
import { Layout, Button, Card, Stack, TextField, DisplayText, List } from '@shopify/polaris'
import { _frankieAppHost } from '../../_constants/globals'
import { PrintTool } from 'react-print-tool'
import './QRCode.css'
var QRCode = require('qrcode.react')


class VisitorQR extends Component {
  constructor (props) {
    super(props)

    this.state = {
      pageSelected: 'all',
      location: '',
      storeDomain: this.props.storeDomain
    }
  }

  /**
   * React lifecycle call, first one to get called after component mounted (after render)
   */
  componentDidMount () {
    // something
  }

  /**
   * React lifecycle call. If props change this method will be triggered.
   * Async logic for when shopId is obtained from API, as initially it will be undefined
   */
  componentDidUpdate (prevProps) {
    // something
  }

  handlePrint = () => {
    PrintTool.printExistingElement("#vQR")
    }

  render () {
    const { location, storeDomain } = this.state
    const smallScreen = window && window.innerWidth < 700 ? true : false
    return (
      <div>
        <div>
          <Layout >
            <Layout.Section >
            <Stack distribution="trailing">
                    <Button primary
                      onClick= {() => this.handlePrint()}
                      disabled = {smallScreen}
                      >{ smallScreen ? 'Print using your desktop' : 'Print this page'}</Button>
                  </Stack><br/>
              <Card title="" sectioned>
                <TextField
                    id='location'
                    value={location}
                    onChange={this.handleChange('location')}
                    placeholder='Enter a name or code'
                    label='Location (if you have multiple)'
                    type='text'
                    helpText='The QR code below will include this location info'
                  />
              </Card>
            </Layout.Section>
          </Layout>
        </div>
        <div id="vQR">
          <Layout >
            <Layout.Section >
              <Card title="" sectioned secondary>
                <DisplayText size="large">Visitor registration</DisplayText><br/>
                <List type="number">
                    <List.Item>Point your phone camera at this QR code (Android phones, try with the 'QR & Barcode Scanner' App)</List.Item>
                    <List.Item>A web page will open (or, simply go to <b>{storeDomain}/safe</b>)</List.Item>
                    <List.Item>Fill in your contact details</List.Item>                      
                </List><br/>
                <p>This information is being gathered for potential future contact tracing.</p><br/>
                <p>Please ask for help if you need assistance or your phone is unable to scan this code. Thank you.</p>
              </Card>
            </Layout.Section>
            <Layout.Section>
                <Card title="" sectioned>
                  <div className='qrCode' style={{ textAlign: 'center'}}>
                    <QRCode id='qr' value={`"https://${storeDomain}/safe?s=${getShopDomain()}&l=${location}"`}  renderAs='svg' size={1024}/>
                  </div>
                  <div style={{float: 'right'}}>
                    <img src={`${_frankieAppHost}/assets/sk_logo.png`} alt="powered by Hiplee" height="32px"></img>
                  </div>
                </Card>
              </Layout.Section>
            </Layout>
        </div>
      </div>
    )
  }

  handleChange = (field) => {
    return (value) => { 
      this.setState({ [field]: value })
    }
  }

}

export default withStyles(globalStyles)(VisitorQR)