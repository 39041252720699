import React, { Component } from 'react'
import { connect } from 'react-redux'
import withStyles from 'react-jss'
import globalStyles from '../../utilities/styles'
import { Layout, Button, Page, DatePicker, Card, Stack, Form, FormLayout } from '@shopify/polaris'
import hipleeApiService from '../../services/hipleeApi'
import store from '../../_redux/store'
import { addNotificationById } from '../../_redux/actions/notifications'
import { NOTIFICATIONS } from '../../_constants/notifications'
import { CSVLink } from "react-csv"

class ExportContacts extends Component {
  constructor (props) {
    super(props)
    const d = new Date()
    var dMinus = new Date()
    dMinus.setDate( dMinus.getDate() - 1 )
    this.state = {
      month: d.getMonth(),
      year: d.getFullYear(),
      selected: {
        start: dMinus,
        end: d
      },
      csvData : [],
      loading : false
    }
  }

  /**
   * React lifecycle call, first one to get called after component mounted (after render)
   */
  componentDidMount () {
    // something
  }

  /**
   * React lifecycle call. If props change this method will be triggered.
   * Async logic for when shopId is obtained from API, as initially it will be undefined
   */
  componentDidUpdate (prevProps) {
    // something
  }

  exportHandler = async () => {
    this.setState({ loading: true })
    const { selected } = this.state
    const f = new Date(selected.end).getTime() + (23*60*60*1000) //add 23 hours to get the end of the day
    const t = new Date(selected.start).getTime()
    let responseJSON
    await hipleeApiService.getContacts(store.getState().token, f, t).then(async response => {
      if (response.status !== 200) {
        store.dispatch(addNotificationById(NOTIFICATIONS.CSV_ERROR.id))
      } else {
        responseJSON = await response.json()
        // convert timestamp to local date/time before export
        const formattedJSON = responseJSON.values.map( item => {
          return {
                  datetime: `${new Date(parseInt(item.datetime))}`,
                  fullname: item.fullname,
                  email: item.email,
                  phone: item.phone,
                  location: item.location
            }
        })
        this.setState({ csvData: formattedJSON })
        this.setState({ loading: false })
        if (formattedJSON.length > 0) {
          store.dispatch(addNotificationById(NOTIFICATIONS.CSV_SUCCESS.id))
        } else {
          store.dispatch(addNotificationById(NOTIFICATIONS.CSV_NOT_FOUND.id))
        }
      }
    })
    .catch(error => {
      store.dispatch(addNotificationById(NOTIFICATIONS.CSV_ERROR.id))
    })


  }

  render () {
    const { csvData, loading } = this.state
    const smallScreen = window && window.innerWidth < 700 ? true : false
    const today = new Date()
    const lastThirtyDays = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
    return (
      <Page
        title="Export"
      >
        <Layout>
          <Layout.AnnotatedSection
            title=""
            description="Export the contact details of visitors that came to your store, for any date range (within the last 30 days).
            Click Export and then the Download CSV link."
          >
            <Card sectioned>
              <Form>
                  <FormLayout>
                      <DatePicker
                      month={this.state.month}
                      year={this.state.year}
                      onChange={this.handleChange}
                      onMonthChange={this.handleMonthChange}
                      selected={this.state.selected}
                      allowRange={true}
                      disableDatesBefore = {lastThirtyDays}
                      disableDatesAfter = {today}
                      />
                      <Stack distribution="trailing">
                        {csvData.length > 0 && !smallScreen && <CSVLink 
                                                  data={csvData}
                                                  filename={`export${new Date().getTime()}.csv`}
                                                  target="_blank"
                                                >
                                                  Download CSV
                                                </CSVLink>
                        }
                        {csvData.length > 0 && smallScreen && <p>Download using your desktop</p>}
                        <Button primary 
                          onClick= {() => this.exportHandler()}
                          disabled = {loading}
                        >
                          {loading ? 'Loading' : 'Export'}
                        </Button>
                      </Stack>
                  </FormLayout>
                </Form>
              </Card>
          </Layout.AnnotatedSection>
        </Layout>
        </Page>
    )
  }

  handleChange = value => {
    this.setState({ selected: value })
  }

  handleMonthChange = (month, year) => {
    this.setState({
      month,
      year
    })
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    shopId: state.shop.id
  }
}

export default connect(mapStateToProps)(withStyles(globalStyles)(ExportContacts))