import { SET_TOKEN } from '../actions/token'

const initialState = null

export const token = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return action.payload.token || state
    default:
      return state
  }
}