import React, { Component } from 'react'
import withStyles from 'react-jss'
import globalStyles from '../../utilities/styles'
import { Page, Card, Layout, TextContainer, Heading, List, Subheading } from '@shopify/polaris'


class LearnMore extends Component {
  constructor (props) {
    super(props)

    this.state = {
    }
  }

  /**
   * React lifecycle call, first one to get called after component mounted (after render)
   */
  componentDidMount () {
    // something
  }

  /**
   * React lifecycle call. If props change this method will be triggered.
   * Async logic for when shopId is obtained from API, as initially it will be undefined
   */
  componentDidUpdate (prevProps) {
    // something
  }

  render () {
    return (
        <Page
            title="Introduction"
        >
            <Layout >
                <Layout.Section >
                <Card title="" sectioned>
                    <TextContainer spacing="loose">
                        <Heading>Safe Keeper</Heading>
                        <p>
                        This app is a tool to assist with contact tracing - one of the key strategies utilised globally by 
                        health authorities to slow and prevent the spread of the virus. Efficient contact tracing relies upon
                         good data of people's movements. In addition to being good practice, the recording of visitor contact details
                          to assist with contact tracing, is a requirement in many jurisdictions throughout the World</p>
                        <Heading>What is contact tracing?</Heading>
                        <p>
                        Tracing the movements of people, and whom they have interacted with whilst infectious, 
                        is critical to rapidly controlling and reducing the spread of a virus during a pandemic. 
                        Contact tracing allows health authorities to gain invaluable information about who the infectious
                         people have come into contact with, which assists them with rapidly testing and isolating probable infections.</p>
                    </TextContainer>
                </Card>
                <Card title="" sectioned>
                    <TextContainer spacing="loose">
                        <Heading>Getting Started</Heading>
                        <p>This app enables visitors to your store to record their contact details in a contactless manner.</p>
                        <p>There are two ways to do so:</p>
                        <List type="number">
                            <List.Item>Generate QR and display for contactless, self-serve</List.Item>
                            <List.Item>Manual Entry by your staff</List.Item>
                        </List>
                        <Subheading>Generate QR</Subheading>
                        <List type="number">
                            <List.Item>Click on the 'Generate QR button' button</List.Item>
                            <List.Item>Click on the 'Print / Display' button</List.Item>
                            <List.Item><i>(optionally)</i> Enter a location name, if you have multiple stores</List.Item>
                            <List.Item>Finally, print or display using a device where the visitors can scan the QR code</List.Item>                           
                        </List>
                        <Subheading>Manual Entry</Subheading>
                        <List type="number">
                            <List.Item>Click the 'Add Visitor' button</List.Item>
                            <List.Item>Enter visitor details and 'Save'</List.Item>
                        </List>
                    </TextContainer>
                </Card>
                <Card title="" sectioned>
                    <TextContainer spacing="loose">
                        <Heading>What happens to the contact records?</Heading>
                        <p>
                            The contact records are date/time stamped and stored securely. 
                            You can export these records within a date range from the 'Export' screen.
                        </p>
                        <p>
                            Please note that our data retention period is 30 days, after which this data is destroyed.
                        </p>
                    </TextContainer>
                </Card>
                </Layout.Section>
            </Layout>
        </Page>      
    )
  }

}

export default withStyles(globalStyles)(LearnMore)