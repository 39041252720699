import React from 'react'
import withStyles from 'react-jss'
import globalStyles from '../../utilities/styles'
import { _frankieAppHost } from '../../_constants/globals'
import { EmptyState } from '@shopify/polaris'

/**
 *
 * @param {*} props
 */
const InMaintenancePage = (props) => {
  const { classes } = props

  return (
    <div className={ classes.spacing_page }>
      <EmptyState
        heading="Frankie is currently under maintenance"
        image={`${_frankieAppHost}/assets/404.svg`}
      >
        <p>We are performing a planned maintenance, please check back at a later time.</p>
      </EmptyState>
    </div>
  )
}

export default withStyles({...globalStyles})(InMaintenancePage)